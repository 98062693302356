.social-container {
    background: #eee;
    padding: 10px 10px;
  }

a.social {
    margin: 0 1rem;
    transition: transform 250ms;
    display: inline-block;
  }

a.social:hover {
    transform: translateY(-2px);
  }

a.linkedin {
    color: #0E76A8;
    font-size: xx-large;
  }
  
a.github {font-size: xx-large;
    color: #030303;
  }
  
a.email { font-size: xx-large;
    color:#BB001B;
  }
  